/*IMPORT CSS*/
@import url('./assets/styles/variable-color.css');

#root {
    min-height: 100vh;
}

.App {
    text-align: center;
    min-height: 100vh;
    background: url("../public/images/b2.jpeg") center/cover no-repeat;
}

.container {
    height: 100vh;
    display: grid;
    background: var(--color-background);
    backdrop-filter: blur(3px) grayscale(50%);
    grid-template-rows: 6% 75% 19%;
    font-family: exo-2, sans-serif;
}