
.skills-container {
    padding: 0.8em;
    background: var(--color-background);
    color: var(--color-white);
    text-align: center;
    box-shadow: 0 0 10px var(--color-black);
    overflow: hidden;
}

.skills-title {
    font-size: 1em;
    margin-bottom: 20px;
    color: var(--color-blue);
    text-shadow: 0 1px 0 var(--color-black);
}

.skills-grid {
    display: flex;
    gap: 0.7em;
    justify-content: space-around;
}

.skill-card {
    background: var(--color-brown-claire);
    padding: 10px;
    border-radius: 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    align-items: center;
    min-width: 75px;
}

.skill-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 0 20px var(--color-blue);
}

.skill-icon {
    width: 2em;
    margin-bottom: 10px;
    transition: transform 0.3s ease;
}

.skill-card:hover .skill-icon {
    transform: rotate(360deg);
}

.skill-name {
    font-size: 0.7em;
    margin: 0;
    color: var(--color-blue);
    text-shadow: 1px 1px 0 var(--color-black);
}

.waiting-card {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-brown-claire);
    padding: 10px;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
}

.waiting-text {
    font-size: 1.2rem;
    color: var(--color-pink);
    white-space: nowrap;
    position: absolute;
    animation: slide-text 7s linear infinite;
}

@keyframes slide-text {
    0% {
        transform: translateX(50%);
    }
    50% {
        transform: translateX(-50%);
    }
    100% {
        transform: translateX(50%);
    }
}

/* MEDIA QUERY */

@media (max-width: 990px) {
    .skills-container {
        padding: 0;
    }

    .skills-grid {
        animation: slide-text 20s linear infinite;
    }
}