
.body {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.main-home {
    color: var(--color-white);
    display: grid;
    grid-template-rows: 10% 5% 85%;
    align-items: center;
}

.main-home h1 {
    padding: 0.5em;
    font-size: 2em;
}

.main-home h2 {
    padding: 0.5em;
    font-size: 1.5em;
}

.main-home p {
    padding: 0.5em 0;
    margin: auto;
    line-height: 1.5;
    font-size: 1.1em;
    width: 50%;
}

.download-link {
    display: inline-block;
}

.main-home a {
    text-decoration: none;
    color: var(--color-blue);
    margin: 0 0.4em 0 0;
}

.main-home a:hover {
    color: var(--color-pink);
    transition: color 0.4s ease-in-out;
}

.select-time {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
}

.select-time p {
    margin: 0.5em 0;
}

.select-time select {
    padding: 0.5em;
    border-radius: 5em;
    border: 1px solid var(--color-blue);
    font-size: 1em;
    background: transparent;
    color: var(--color-white);

}

/* MEDIA QUERY */

@media (max-width: 768px) {
    .main-home h1 {
        font-size: 2em;
    }

    .main-home h2 {
        font-size: 1.5em;
    }

    .main-home p {
        font-size: 1em;
        width: 80%;
    }

    .select-time select {
        font-size: 1em;
    }
}
