
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

header {
    background: var(--color-background);
    border-bottom: 1px solid var(--color-blue);
    box-shadow: 0 0 6px 1px var(--color-blue);
    padding: 1em;
    height: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
}

nav {
    display: block;
    width: 100%;
}

nav a {
    text-decoration: none;
    color: var(--color-white);
}

nav a:hover {
    color: var(--color-blue);
    transition: color 0.3s ease-in-out;
}

nav li:hover {
    text-shadow: 0 1px 0 var(--color-black);
    scale: 1.2;
    transition: 0.3s ease-in-out;
}

nav ul {
    display: flex;
    justify-content: space-around;
}

nav ul li {
    list-style-type: none;
    font-size: 1.2em;
}