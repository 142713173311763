
.main-project {
    overflow: auto;
    scrollbar-color: var(--color-blue) var(--color-black);
    scrollbar-width: thin;
}

.main-project::-webkit-scrollbar {
    width: 10px;
}

.main-project::-webkit-scrollbar-track {
    background-color: var(--color-black);
}

.main-project::-webkit-scrollbar-thumb {
    background-color: var(--color-blue);
    outline: none;
}

.main-project::-webkit-scrollbar-thumb:hover {
    background-color: var(--color-blue);
}

.main-project h1 {
    margin: 0;
    padding: 0.5em;
    font-size: 2em;
    font-weight: bold;
    color: var(--color-white)
}